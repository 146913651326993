<template>
  <div class="p-3">
    <checkEmploymentHistoryCertificate
      :propsData1="getUserFromId"
      :propsData2="getEmploymentHistory"
    ></checkEmploymentHistoryCertificate>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import checkEmploymentHistoryCertificate from "../../components/checkEmploymentHistory/checkEmploymentHistoryCertificate.vue";
import * as encodeDecodeService from "../../services/encodeDecode.service";
export default {
  name: "CheckProfessionalMembershipCertificate",

  components: {
    checkEmploymentHistoryCertificate,
  },
  data() {
    return {
      projectId: encodeDecodeService.cyDecrypt(this.$route.params.projectId),
      userId: encodeDecodeService.cyDecrypt(this.$route.params.userId),
      productId: encodeDecodeService.cyDecrypt(this.$route.params.checkId),
    };
  },
  computed: {
    ...mapGetters(["getEmploymentHistory", "getUserFromId"]),
  },
  methods: {
    ...mapActions(["fetchEmploymentHistory", "fetchUserFromId"]),
  },
  created() {
    this.fetchUserFromId(this.userId);
    this.fetchEmploymentHistory(this.projectId);
  },
};
</script>
